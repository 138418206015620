import { useTranslation } from 'react-i18next';

export const useValidation = (
  baseTranslationKey: string,
): {
  transformErrors: any;
  requiredFieldValidation: any;
  regexFieldValidation: any;
} => {
  const { t } = useTranslation('main');

  const transformErrors = (errorList: any[]) => {
    return errorList.reduce((acc, { field, errorType }) => {
      return {
        ...acc,
        [field]: t(`${baseTranslationKey}.errors.${field}.${errorType}`),
      };
    }, {});
  };

  const requiredFieldValidation = (
    values: Record<string, any>,
    fieldName: string,
  ): Record<string, string> | null => {
    if (!values[fieldName]) {
      return {
        [fieldName]: t(`${baseTranslationKey}.errors.required.${fieldName}`),
      };
    }
    return null;
  };

  const regexFieldValidation = (
    values: Record<string, any>,
    fieldName: string,
    regex: RegExp,
  ): Record<string, string> | null => {
    if (!regex.test(values[fieldName])) {
      return {
        [fieldName]: t(`${baseTranslationKey}.errors.format.${fieldName}`),
      };
    }
    return null;
  };

  return { transformErrors, requiredFieldValidation, regexFieldValidation };
};
