export const NOT_FOUND_ERROR = 'NOT_FOUND_ERROR';
export const UNPROCESSABLE_ENTITY_ERROR = 'UNPROCESSABLE_ENTITY_ERROR';
export const UNAUTHORIZED_ERROR = 'UNAUTHORIZED_ERROR';
export const INVALID_INPUT_ERROR = 'INVALID_INPUT_ERROR';
export const UNEXPECTED_ERROR = 'UNEXPECTED_ERROR';
export const NO_CHANGES_ERROR = 'NO_CHANGES_ERROR';
export const EMPTY_SEARCH_ERROR = 'EMPTY_SEARCH_ERROR';
export const NO_APPS_ERROR = 'NO_APPS_ERROR';
export const NO_APPS_WITH_STATUS_ERROR = 'NO_APPS_WITH_STATUS_ERROR';
export const NO_TEMPLATES_ERROR = 'NO_TEMPLATES_ERROR';
export const NO_TEMPLATES_FILTER = 'NO_TEMPLATES_FILTER';
export const NO_ORGS_ERROR = 'NO_ORGS_ERROR';
export const NO_ORGS_FILTER = 'NO_ORGS_FILTER';
export const NO_PROSPECTS_ERROR = 'NO_PROSPECTS_ERROR';
export const NO_USERS_ERROR = 'NO_USERS_ERROR';
export const NO_APPLICATION_MEMBERSHIPS_ERROR =
  'NO_APPLICATION_MEMBERSHIPS_ERROR';
export const NO_ORGANIZATION_MEMBERSHIPS_ERROR =
  'NO_ORGANIZATION_MEMBERSHIPS_ERROR';
export const NO_CUSTOMER_MEMBERS_ERROR = 'NO_CUSTOMER_MEMBERS_ERROR';
export const NO_BUILDERS_ERROR = 'NO_BUILDERS_ERROR';
export const NO_GALLERY_ERROR = 'NO_GALLERY_ERROR';
export const NO_GALLERY_ITEMS_ERROR = 'NO_GALLERY_ITEMS_ERROR';
export const NO_MERGES_ERROR = 'NO_MERGES_ERROR';
export const NO_MERGES_FILTER = 'NO_MERGES_FILTER';
export const FIELD_BREACHED_PASSWORD = 'FIELD_BREACHED_PASSWORD';
export const GRID_PER_PAGE = 36;

export type ErrorPageType =
  | 'NOT_FOUND_ERROR'
  | 'NO_CHANGES_ERROR'
  | 'UNAUTHORIZED_ERROR'
  | 'UNEXPECTED_ERROR'
  | 'UNPROCESSABLE_ENTITY_ERROR'
  | 'EMPTY_SEARCH_ERROR'
  | 'NO_APPS_ERROR'
  | 'NO_APPS_WITH_STATUS_ERROR'
  | 'NO_TEMPLATES_ERROR'
  | 'NO_TEMPLATES_FILTER'
  | 'NO_ORGS_ERROR'
  | 'NO_ORGS_FILTER'
  | 'NO_BLOCKS_ERROR'
  | 'NO_BLOCKS_FILTER'
  | 'NO_PROSPECTS_ERROR'
  | 'NO_BUILDERS_ERROR'
  | 'NO_USERS_ERROR'
  | 'NO_GALLERY_ERROR'
  | 'NO_GALLERY_ITEMS_ERROR'
  | 'NO_MERGES_ERROR'
  | 'NO_MERGES_FILTER'
  | 'NO_CUSTOMER_MEMBERS_ERROR'
  | 'NO_APPLICATION_MEMBERSHIPS_ERROR'
  | 'NO_ORGANIZATION_MEMBERSHIPS_ERROR';

// user roles
export const ADMIN = 'ADMIN';
export const USER = 'USER';
export const BUILDER = 'BUILDER';

// block-store constants
export const NO_BLOCKS_ERROR = 'NO_BLOCKS_ERROR';
export const NO_BLOCKS_FILTER = 'NO_BLOCKS_FILTER';
export const PRIVATE = 'PRIVATE';
export const PUBLIC = 'PUBLIC';

// App statusses
export const DEVELOPMENT = 'Development';
export const LIVE = 'Live';

// Routing states
export const APPLICATIONOVERVIEW = 'ApplicationOverview';
export const ORGANIZATIONDETAILS = 'OrganizationDetails';
export const DASHBOARD = 'Dashboard';
export const DEFAULT_COLOR = '#11ABD1';
export const COMPANY_PERMISSIONS = {
  createRoles: 'my_bb_customer_role_create',
  deleteRoles: 'my_bb_customer_role_delete',
  updateRoles: 'my_bb_customer_role_update',
  manageCompanySettings: 'my_bb_customer_manage',
  manageCompanyUsersMembership: 'my_bb_customer_membership_manage',
  templateVisibilityCustomer: 'my_bb_template_set_visibility_customer',
  updatePermissions: 'my_bb_customer_role_update_permission',
  manageCompanyGallery: 'my_bb_gallery_manage',
  exportCompanyData: 'my_bb_customer_export',
  viewUserManagement: 'my_bb_customer_membership_view',
  manageUserProvisioning: 'my_bb_customer_manage_user_provisioning',
  moveApplicationsToOrganization: 'my_bb_application_move_organization',
  viewCompanyBlocks: 'my_bb_customer_blocks_view',
  viewBlockInBlockstore: 'block_store_view_block',
  updateBlockInBlockstore: 'block_store_update_block',
  manageBlockOwnersInBlockstore: 'block_store_manage_block_owners',
  viewContract: 'my_bb_customer_contract_view',
};

export const ORGANIZATION_PERMISSIONS = {
  canInviteUsersToOrganization: 'my_bb_organization_invite_user',
  canMergeToApplication: 'my_bb_application_merge_to_production',
  canMergeToSandbox: 'my_bb_application_merge_to_sandbox',
  canPromoteApplication: 'my_bb_application_change_status',
  canPromoteTemplateToOrganization: 'my_bb_application_promote_to_template',
  canRollbackMerge: 'my_bb_application_merge_rollback',
  canCreateApplication: 'my_bb_application_create',
  canDeleteApplication: 'my_bb_application_delete',
  canCreateSandbox: 'my_bb_sandbox_create',
  canDeleteSandbox: 'my_bb_sandbox_delete',
  canTogglePublicTesting: 'my_bb_application_enable_public_testing',
  canChangeOrganizationRole: 'my_bb_organization_change_member_role',
  canManageOrganizationPermissions: 'my_bb_organization_manage_permissions',
  canManageApplicationDevelopmentPermissions:
    'my_bb_application_manage_permissions',
  canInviteUsersToApplication: 'my_bb_application_invite_user',
  canChangeApplicationRole: 'my_bb_application_change_member_role',
  canRemoveMemberFromApplication: 'my_bb_application_remove_user',
  canRemoveMemberFromOrganization: 'my_bb_organization_remove_user',
  canMoveApplicationsIntoOrganization:
    'my_bb_application_move_into_organization',
  canMoveApplicationsOutOfOrganization:
    'my_bb_application_move_out_organization',
  canChangeInviteUserTemplate: 'my_bb_application_change_invite_user_template',
  canManageApplicationHost: 'my_bb_application_manage_hosts',
  canChangeApplicationIdentifier: 'my_bb_application_change_identifier',
  canUpdateApplicationSettings: 'my_bb_application_change_settings',
  canManageOrganizationSettings: 'my_bb_organization_manage_settings',
  canViewApplicationMergeHistory: 'my_bb_application_merge_history_view',
  canListAllOrganizationApplications: 'my_bb_organization_list_applications',
};

export const statusDocumentation =
  'https://docs.bettyblocks.com/en/articles/5796967-using-application-status-in-the-application-development-cycle';
