import React, { createElement, useEffect, useState } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import {
  AnonymousMixpanelProvider,
  ErrorPage,
  ToastManager,
  Tooltip,
} from '@my-bb/common-components';
import Organizations from '@my-bb/organizations';
import ApplicationManager from '@my-bb/application-manager';
import BlockStore from '@my-bb/block-store';
import Customers from '@my-bb/customers';
import { GetEnvVariable } from '@my-bb/environment-variables';
import Galleries from '@my-bb/galleries';
import { ThemeProvider } from 'styled-components';
import { I18nextProvider } from 'react-i18next';
import { constants, routes, logout } from '@my-bb/common';
import { DesignSystemProvider, theme } from '@betty-blocks/design';
import { DesignSystemProvider as DesignSystemProviderV2 } from '@betty-blocks/core-components';
import { i18n } from './utils/i18n';
import { Templates } from './containers/Templates';
import { SettingsPage } from './containers/SettingsPage';
import { TemplateDetail } from './containers/TemplateDetail';
import { MyBB } from './containers/MyBB';
import { DashboardPage } from './containers/DashboardPage';

import { Registration } from './containers/Registration';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { PublicRegistration } from './containers/PublicRegistration';
import { InviteRegistration } from './containers/InviteRegistration';
import { PasswordForgotten } from './containers/PasswordForgotten';
import { ResetPassword } from './containers/ResetPassword';
import { AnonymousClientProvider } from './containers/AnonymousClientProvider/AnonymousProvider';
import { SetPassword } from './containers/SetPassword';
import { GalleryDetection } from './containers/GalleryDetection/GalleryDetection';
import { Gallery, GalleryDetail } from './pages';

const REACT_APP_BASENAME = GetEnvVariable('REACT_APP_BASENAME');

export function App() {
  const [isLoadingScripts, setIsLoadingScripts] = useState(true);
  const REACT_APP_HUBSPOT_ID = GetEnvVariable('REACT_APP_HUBSPOT_ID');
  const hasHubspotID = Boolean(REACT_APP_HUBSPOT_ID);

  useEffect(() => {
    if (hasHubspotID && isLoadingScripts) {
      const script = document.createElement('script');
      script.src = `//js.hs-scripts.com/${REACT_APP_HUBSPOT_ID}.js`;
      script.id = 'hs-script-loader';
      script.async = true;
      script.defer = true;
      script.onload = (): void => {
        setIsLoadingScripts(false);
      };
      script.onerror = (): void => {
        setIsLoadingScripts(false);
      };
      document.body.appendChild(script);
    } else {
      setIsLoadingScripts(false);
    }
  });

  if (isLoadingScripts) {
    return null;
  }

  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <DesignSystemProvider>
          <DesignSystemProviderV2>
            <Sentry.ErrorBoundary
              fallback={
                <ErrorPage type={constants.UNEXPECTED_ERROR} isFullPage />
              }
            >
              <BrowserRouter basename={REACT_APP_BASENAME}>
                <Tooltip autoPosition position="bottom" events />
                <ToastManager>
                  <Routes>
                    <Route
                      path={routes.REGISTRATION}
                      element={
                        <AnonymousMixpanelProvider>
                          <AnonymousClientProvider>
                            <Registration />
                          </AnonymousClientProvider>
                        </AnonymousMixpanelProvider>
                      }
                    />
                    {/* <Route
                      path={routes.PUBLIC_REGISTRATION}
                      element={
                        <AnonymousMixpanelProvider>
                          <AnonymousClientProvider>
                            <PublicRegistration />
                          </AnonymousClientProvider>
                        </AnonymousMixpanelProvider>
                      }
                    /> */}

                    <Route path={routes.GALLERY} element={<GalleryDetection />}>
                      <Route index element={<Gallery />} />
                      <Route
                        path={routes.GALLERY_DETAIL_BASE}
                        element={<GalleryDetail />}
                      />
                    </Route>

                    <Route
                      path={routes.COMPLETE_REGISTRATION}
                      element={
                        <AnonymousMixpanelProvider>
                          <AnonymousClientProvider>
                            <InviteRegistration />
                          </AnonymousClientProvider>
                        </AnonymousMixpanelProvider>
                      }
                    />
                    <Route
                      path={routes.PASSWORD_FORGOTTEN}
                      element={
                        <AnonymousMixpanelProvider>
                          <AnonymousClientProvider>
                            <PasswordForgotten />
                          </AnonymousClientProvider>
                        </AnonymousMixpanelProvider>
                      }
                    />
                    <Route
                      path={routes.RESET_PASSWORD}
                      element={
                        <AnonymousMixpanelProvider>
                          <AnonymousClientProvider>
                            <ResetPassword />
                          </AnonymousClientProvider>
                        </AnonymousMixpanelProvider>
                      }
                    />
                    <Route
                      path={routes.SET_PASSWORD}
                      element={
                        <AnonymousMixpanelProvider>
                          <AnonymousClientProvider>
                            <SetPassword />
                          </AnonymousClientProvider>
                        </AnonymousMixpanelProvider>
                      }
                    />
                    <Route path="/" element={<MyBB />}>
                      <Route index element={<DashboardPage />} />
                      <Route
                        path={`${routes.APPLICATIONS_BASE}/*`}
                        element={<ApplicationManager />}
                      />
                      <Route
                        path={`${routes.ORGANIZATIONS_BASE}/*`}
                        element={<Organizations />}
                      />
                      <Route
                        path={`${routes.BLOCK_STORE_BASE}/*`}
                        element={<BlockStore />}
                      />
                      <Route
                        path={`${routes.CUSTOMER}/*`}
                        element={<Customers />}
                      />
                      <Route
                        path={`${routes.GALLERIES_BASE}/*`}
                        element={<Galleries />}
                      />
                      <Route path={routes.TEMPLATES} element={<Templates />} />
                      <Route
                        path={routes.TEMPLATE_DETAIL}
                        element={<TemplateDetail />}
                      />
                      <Route
                        path={routes.SETTINGS}
                        element={<SettingsPage />}
                      />
                      <Route
                        path={routes.LOGOUT}
                        element={createElement(logout)}
                      />
                    </Route>
                    <Route
                      path="*"
                      element={<ErrorPage type={constants.NOT_FOUND_ERROR} />}
                    />
                  </Routes>
                </ToastManager>
              </BrowserRouter>
            </Sentry.ErrorBoundary>
          </DesignSystemProviderV2>
        </DesignSystemProvider>
      </ThemeProvider>
    </I18nextProvider>
  );
}
