import styled from 'styled-components';
import { Text } from '@betty-blocks/design';
import { BettyLogo } from '../BettyLogo';

export const Content = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  min-height: 100vh;
  padding: 1.125rem;
  background: ${({ theme }) => theme.global.colors.white};

  ${({
    theme,
  }): string => `@media (min-width: ${theme.global.breakpoints.small.value}px) {
    width: 33%;
    padding: 2.25rem;
  }`}
`;

export const ContentSection = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 38.5rem;
`;

export const FooterSection = styled.footer`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  max-width: 38.5rem;
  margin: 1rem auto 0;
`;

export const BettyBlocksLogo = styled(BettyLogo)`
  width: 14.25rem;
  color: ${({ theme }) => theme.global.colors.pink500};
`;

export const FooterLogo = styled(BettyLogo)`
  width: 7.6rem;
  height: 1.5rem;
  color: ${({ theme }) => theme.global.colors.grey700};
`;

export const FooterText = styled(Text)`
  padding-top: 0.5rem;
`;
