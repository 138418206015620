import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LeftIcon, RightIcon } from '@betty-blocks/core-icons';
import { CloseIcon } from '@betty-blocks/design';

import { useKeyDown } from '@my-bb/common-components';

import {
  ImageCarouselModalButton,
  ImageCarouselModalLayer,
  ImageCarouselNavigationBars,
  ImageCarouselNavigationBarsButton,
  ImageCarouselNavigationButton,
  ImageCarouselNavigationButtons,
} from './styled';

interface Props {
  images: string[];
}

export function ImageCarousel({ images }: Props): JSX.Element {
  const { t } = useTranslation('main');
  const [imageIndex, setImageIndex] = useState(0);
  const [showImageModal, setShowImageModal] = useState(false);

  const handlePrevious = (): void => {
    setImageIndex((prev): number =>
      prev === 0 ? images.length - 1 : prev - 1,
    );
  };

  const handleNext = (): void => {
    setImageIndex((prev): number =>
      prev === images.length - 1 ? 0 : prev + 1,
    );
  };

  const handleImageClick = (): void => {
    setShowImageModal(true);
  };

  const handleCloseModal = (): void => {
    setShowImageModal(false);
  };

  useKeyDown(['Escape'], handleCloseModal);
  useKeyDown(['ArrowLeft'], handlePrevious);
  useKeyDown(['ArrowRight'], handleNext);

  return (
    <ImageCarouselModalLayer
      isOpen={showImageModal}
      level={imageIndex}
      total={images.length}
    >
      <div className="modal">
        {showImageModal && (
          <ImageCarouselModalButton
            type="button"
            icon={<CloseIcon color="white" />}
            onClick={handleCloseModal}
          >
            {t('Gallery.modalClose')}
          </ImageCarouselModalButton>
        )}
        <div className="carousel-wrapper">
          <div className="image-carousel-container">
            {images.map((image) => (
              <button
                className="image-button"
                key={image}
                type="button"
                onClick={handleImageClick}
              >
                <img alt="detail-carousel" src={image} />
              </button>
            ))}
          </div>
          <div className="navigation">
            <ImageCarouselNavigationBars>
              {images.map((image, index) => (
                <ImageCarouselNavigationBarsButton
                  active={imageIndex === index}
                  key={`navbar-${image}`}
                  type="button"
                  onClick={(): void => setImageIndex(index)}
                />
              ))}
            </ImageCarouselNavigationBars>
            <ImageCarouselNavigationButtons>
              <ImageCarouselNavigationButton onClick={handlePrevious}>
                <LeftIcon size="large" color="white" />
              </ImageCarouselNavigationButton>
              <ImageCarouselNavigationButton onClick={handleNext}>
                <RightIcon size="large" color="white" />
              </ImageCarouselNavigationButton>
            </ImageCarouselNavigationButtons>
          </div>
        </div>
      </div>
    </ImageCarouselModalLayer>
  );
}
