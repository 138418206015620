import React, { useState, useContext, ChangeEvent } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { mutations } from '@my-bb/common';
import { PasswordIndicator, toastQueueContext } from '@my-bb/common-components';
import { errorUtils } from '@my-bb/common-utils';
import { MainPage } from '../../components/MainPage';
import {
  InputWrapper,
  BiggerInput,
  PasswordBiggerInput,
  ConfirmButton,
  Title,
  SubTitle,
} from '../../components/MainPage/styled';

export function RegistrationForm(): JSX.Element {
  const [values, setValues] = useState({
    password: '',
    name: '',
  });

  const [errors, setErrors] = useState({
    password: undefined,
    name: undefined,
  });
  const { t } = useTranslation('main');
  const { addToToastQueue } = useContext(toastQueueContext);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { password, name } = values;
  const utmSource = searchParams.get('utm_source');
  const utmMedium = searchParams.get('utm_medium');
  const utmCampaign = searchParams.get('utm_campaign');
  const utmTerm = searchParams.get('utm_term');
  const redirectUrl = `${searchParams.get(
    're',
  )}?utm_source=${utmSource}&utm_campaign=${utmCampaign}&utm_medium=${utmMedium}&utm_term=${utmTerm}`;

  const [acceptInvitation, { loading }] = useMutation(
    mutations.acceptInvitation,
    {
      variables: {
        jwt: token,
        ...values,
      },
      onCompleted: (completeData) => {
        const error = errorUtils.extractError(completeData.acceptInvitation);

        if (!error.type) {
          window.location.replace(redirectUrl);
          return;
        }

        if (Object.keys(error.fields).length) {
          setErrors({
            ...errors,
            ...error.fields,
          });
          return;
        }

        if (error.type !== undefined) {
          addToToastQueue(errorUtils.toastErrorData(error));
        }
      },
    },
  );

  const handleChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    const { name: targetName, value } = evt.target;
    setValues({ ...values, [targetName]: value });
    setErrors({ ...errors, [targetName]: undefined });
  };

  const isSubmitBtnActive = !errors.name && !errors.password;
  const handleSubmit = (): void => {
    if (!isSubmitBtnActive) return;
    acceptInvitation();
  };

  return (
    <MainPage>
      <Title>{t('Register.title')}</Title>
      <SubTitle color="grey700">{t('Register.subtitle')}</SubTitle>
      <InputWrapper>
        <BiggerInput
          label="full name"
          name="name"
          value={name}
          error={errors.name}
          onChange={handleChange}
        />
      </InputWrapper>
      <InputWrapper>
        <PasswordBiggerInput
          label="password"
          name="password"
          value={password}
          onChange={handleChange}
          error={errors.password}
        />
      </InputWrapper>
      <PasswordIndicator value={password} />
      <ConfirmButton
        size="large"
        onClick={handleSubmit}
        disabled={!isSubmitBtnActive}
      >
        {t('Register.complete')}
      </ConfirmButton>
    </MainPage>
  );
}
