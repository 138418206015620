import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Text } from '@betty-blocks/design';
import type { ThemeType } from '@betty-blocks/design';
import { Avatar } from '@my-bb/common-components';
import { SidebarItem } from '../SidebarItem';

export const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 16;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 4rem;
  padding: 0;
  background: ${({ theme }): string => theme.global.colors.grey100};
`;

export const DashboardSidebarItem = styled(SidebarItem)`
  margin-bottom: 2rem;
`;

export const NavList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const DropOut = styled(NavList)<{ isVisible: boolean }>`
  position: absolute;
  bottom: -0.25rem;
  left: 4.5rem;
  z-index: 12;
  min-width: 16.875rem;
  background: ${({ theme }): string => theme.global.colors.white};
  border-radius: 0.125rem;
  box-shadow: 0 0.25rem 1rem 0 #d8d8d8;
  visibility: ${({ isVisible }): string => (isVisible ? 'visible' : 'hidden')};
  opacity: ${({ isVisible }): number => (isVisible ? 1 : 0)};
  transition: ${({ isVisible }): string =>
    isVisible
      ? 'visibility 0s linear 0s, opacity 300ms'
      : 'visibility 0s linear 300ms, opacity 300ms'};

  & li {
    justify-content: flex-start;
  }

  & li:first-of-type {
    display: block;
    padding-top: 0.5rem;

    &::after {
      display: block;
      margin-right: 0.75rem;
      margin-bottom: 0.5rem;
      margin-left: 0.75rem;
      padding-bottom: 0.5rem;
      border-bottom: 0.0625rem solid
        ${({ theme }): string => theme.global.colors.grey300};
      content: '';
    }
  }
  & li:first-of-type a {
    align-items: center;
  }
  & li:last-of-type {
    display: block;
    padding: 0.5rem 0;

    &::before {
      display: block;
      margin-right: 0.75rem;
      margin-bottom: 0.5rem;
      margin-left: 0.75rem;
      border-top: 0.0625rem solid
        ${({ theme }): string => theme.global.colors.grey300};
      content: '';
    }
  }
`;

const linkStyle = ({
  theme,
}: {
  theme: ThemeType;
}): FlattenSimpleInterpolation => css`
  display: flex;
  flex: 1;
  align-items: baseline;
  justify-content: flex-start;
  padding: 0 0.75rem;
  white-space: nowrap;
  text-decoration: none;
  word-break: break-word;
  transition: background-color 0.2s ease;

  & .link-icon {
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  & svg {
    transition: fill 0.2s ease;
  }

  &:hover {
    background-color: ${theme?.global?.colors?.turquoise50};

    & .link-icon {
      opacity: 1;
    }

    & svg {
      fill: ${theme?.global?.colors?.grey900};
    }
  }
`;

export const Link = styled(NavLink)`
  ${linkStyle}
`;

export const ExternalLink = styled.a`
  ${linkStyle}
`;

export const NavItem = styled(NavLink)`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 64px;
  padding: 0.625rem 0.75rem;

  &.active {
    background-color: ${({ theme }): string => theme.global.colors.grey400};
  }

  &:hover:not(.active) {
    background-color: ${({ theme }): string => theme.global.colors.grey200};
    transition: all 0.2s;
    transition-delay: 0.05s;
  }

  &:hover svg,
  &.active svg {
    fill: ${({ theme }): string => theme.global.colors.grey900};
  }
`;

export const LogoIcon = styled.img`
  width: 2rem;
  height: 1.75rem;
`;

export const UserIcon = styled(Avatar)<{ isProfile?: boolean }>`
  width: ${({ isProfile }): string | undefined =>
    isProfile ? '3rem' : undefined};
  height: ${({ isProfile }): string | undefined =>
    isProfile ? '3rem' : undefined};
  margin: 12px 0;
  line-height: ${({ isProfile }): string | undefined =>
    isProfile ? '2.5rem' : undefined};
`;

export const LinkContent = styled.div<{ isProfile?: boolean }>`
  padding: ${({ isProfile }): string =>
    isProfile ? '0.4375rem 0.25rem 0.5625rem 0' : '0.5rem 0.25rem'};
`;

export const LinkTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const LinkText = styled(Text)<{ isProfile?: boolean }>`
  display: block;
  max-width: ${({ isProfile }): string => (isProfile ? '10rem' : '13rem')};
  padding-left: ${({ isProfile }): string => (isProfile ? '1rem' : '0.75rem')};
  overflow: hidden;
  line-height: ${({ isProfile }): string => (isProfile ? '1.25rem' : '1.5rem')};
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }): string => theme.global.colors.grey900};
`;

export const LinkDescription = styled(Text)<{ isProfile: boolean }>`
  display: block;
  max-width: ${({ isProfile }): string => (isProfile ? '10rem' : '13rem')};
  padding-left: ${({ isProfile }): string => (isProfile ? '1rem' : '1.75rem')};
  overflow: hidden;
  font-size: 0.75rem;
  line-height: ${({ isProfile }): string => (isProfile ? '1.5rem' : '1.25rem')};
  white-space: nowrap;
  text-overflow: ellipsis;
`;
