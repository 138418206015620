import React from 'react';
import { Outlet } from 'react-router-dom';
import {
  MybbContext,
  MixpanelProvider,
  Appcues,
  Intercom,
  MeProvider,
} from '@my-bb/common-components';
import { Sidebar } from '../../components/Sidebar';
import { Container } from '../../styled';
import { InvalidAuthModal } from '../../components/InvalidAuthModal';
import { ClientProvider } from '../ClientProvider/ClientProvider';

export function MyBB() {
  return (
    <ClientProvider>
      <MeProvider>
        <MixpanelProvider>
          <MybbContext>
            <Appcues />
            <Intercom />
            <InvalidAuthModal />
            <Container>
              <Sidebar />
              <Outlet />
            </Container>
          </MybbContext>
        </MixpanelProvider>
      </MeProvider>
    </ClientProvider>
  );
}
